import * as React from "react"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { StaticImage } from "gatsby-plugin-image"

const NavBar = ({ siteTitle }) => {
  const intl = useIntl();
  return (
    <nav>
      <ul>
        <li className="logo">
          <Link to={`/${intl.locale}`}>
            <StaticImage
              className="site-logo"
              layout="fixed"
              src="../images/logo_navbar.svg"
              height={32}
              quality={95}
              placeholder="none"
              alt={siteTitle}
            />
          </Link>
        </li>
        <li>
          <Link to={`/${intl.locale}`}>
            <FormattedMessage id={"navlinks.about"} />
          </Link>
        </li>
        <li>
          <Link to={`/${intl.locale}/resources`}>
            <FormattedMessage id={"navlinks.resources"} />
          </Link>
        </li>
        <li>
          <Link to={`/${intl.locale}/talk-with-us`}>
            <FormattedMessage id={"navlinks.talkWithUs"} />
          </Link>
        </li>
        <li>
          <Link to={`/${intl.locale}/shelters`}>
            <FormattedMessage id={"navlinks.shelters"} />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
