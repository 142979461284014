import * as React from "react"
import NavBar from "./navbar"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useEffect, useRef } from "react"

const Layout = ({ location, siteTitle, fullscreen, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const headerRef = useRef();

  useEffect(() => {
    let scrollListener = () => {
      document.body.style.setProperty(
        "--scroll",
        String(window.pageYOffset / (document.body.offsetHeight - window.innerHeight))
      );
      let headerYOffset = Math.max(0, headerRef.current.clientHeight - window.pageYOffset);
      document.body.style.setProperty(
        "--headerYOffset",
        String(headerYOffset + "px")
      );
    }
    scrollListener();
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])


  return (
    <div className={`global-wrapper ${fullscreen ? "fullscreen" : "" }`} data-is-root-path={isRootPath}>
      <header className="global-header" ref={headerRef}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Fira+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic" media="all" />
       <NavBar siteTitle={siteTitle} />
      </header>
      <main>{children}</main>
      <footer>
        <div className="copyright">
          <FormattedMessage id="footer.credits" />
        </div>
      </footer>
    </div>
  )
}

export default Layout
